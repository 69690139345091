import { render, staticRenderFns } from "./withdrawal-page.vue?vue&type=template&id=74c1580e&scoped=true&"
import script from "./withdrawal-page.vue?vue&type=script&lang=js&"
export * from "./withdrawal-page.vue?vue&type=script&lang=js&"
import style0 from "./withdrawal-page.vue?vue&type=style&index=0&id=74c1580e&prod&lang=css&"
import style1 from "./withdrawal-page.vue?vue&type=style&index=1&id=74c1580e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74c1580e",
  null
  
)

export default component.exports